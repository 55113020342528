<template>
	<main class="page-container container">
		<div class="section__top mb-20"
		>
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile  ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					{{ $t('tariffs') }}
				</AppText>
			</div>
		</div>

		<div class="section__body"
			 :class="isMobileSmall ? 'mb-30' : 'mb-60'"
		>
			<div class="table-block secondary-table"
				 :class="isMobileSmall ? 'mb-30' : 'mb-60'"
			>
				<table>
					<thead>
					<tr>
						<th style="max-width: 300px;">{{ $t('servicesMenu') }}</th>
						<th style="max-width: 300px;">{{ $t('customer') }}</th>
						<th style="max-width: 300px;">{{ $t('supplier') }}</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td style="max-width: 300px; background: #F3F8FC">
							<AppText size="14" line-height="18" weight="700">{{ $t('eShop') }}</AppText>
						</td>
						<td style="max-width: 300px;">
							<AppText size="14" line-height="18" class="mb-5">
								<b>0,15% от суммы сделки</b> (но не более <b>10 000 сум</b>)
							</AppText>
							<AppText size="12" line-height="21" weight="700" class="color-danger">{{$t('collateral')}}
								3%
							</AppText>
						</td>

						<td style="max-width: 300px;">
							<AppText size="14" line-height="18" class="mb-5">
								<b>0,15% от суммы сделки</b>
							</AppText>
							<span class="color-danger">{{$t('collateral')}}  3%</span>
						</td>
					</tr>

					<tr>
						<td style="max-width: 300px; background: #F3F8FC">
							<AppText size="14" line-height="18" weight="700">{{ $t('auction') }}</AppText>
						</td>
						<td style="max-width: 300px;">
							<AppText size="14" line-height="18" class="mb-5">
								<b> 0,15% от суммы сделки</b> (но не более <b>10 000 сум</b>)
							</AppText>
							<AppText size="12" line-height="21" weight="700" class="color-danger">{{$t('collateral')}}
								3%
							</AppText>
						</td>
						<td style="max-width: 300px;">
							<AppText size="14" line-height="18" class="mb-5">
								<b>0,15% от суммы сделки</b>
							</AppText>
							<AppText size="12" line-height="21" weight="700" class="color-danger">{{$t('collateral')}}
								3%
							</AppText>
						</td>
					</tr>

					<tr>
						<td style="max-width: 300px; background: #F3F8FC">
							<AppText size="14" line-height="18" weight="700">{{ $t('selectionOfTheBestOffers') }}</AppText>
						</td>
						<td style="max-width: 300px;">
							<AppText size="14" line-height="18" class="mb-5">
								<b>0,15% от суммы сделки</b> (но не более <b>10 000 сум</b>)
							</AppText>
							<AppText size="12" line-height="21" weight="700" class="color-danger">{{$t('collateral')}}
								3%
							</AppText>
						</td>
						<td style="max-width: 300px;">
							<AppText size="14" line-height="18" class="mb-5">
								<b>0,15% от суммы сделки</b> (но не более <b>4 млн. сум</b>)
							</AppText>
							<AppText size="12" line-height="21" weight="700" class="color-danger">{{$t('collateral')}}
								3%
							</AppText>
						</td>
					</tr>


					<tr>
						<td style="max-width: 300px; background: #F3F8FC">
							<AppText size="14" line-height="18" weight="700">{{ $t('tender') }}</AppText>
						</td>
						<td style="max-width: 300px;">
							<AppText size="14" line-height="18" class="mb-5">
								<b>0,15% от суммы сделки</b> (но не более <b>10 000 сум</b>)
							</AppText>
							<AppText size="12" line-height="21" weight="700" class="color-danger">{{$t('collateral')}}
								3%
							</AppText>
						</td>
						<td style="max-width: 300px;">
							<AppText size="14" line-height="18" class="mb-5">
								<b>0,15% от суммы сделки</b> (но не более <b>4 млн. сум</b>)
							</AppText>
							<AppText size="12" line-height="21" weight="700" class="color-danger">{{$t('collateral')}}
								3%
							</AppText>
						</td>
					</tr>

					</tbody>
				</table>
			</div>

		</div>
	</main>
</template>

<script>
	import AppText from "../../components/shared-components/AppText";

	export default {
		name: "index",
		components: {AppText}
	}
</script>

<style lang="scss" scoped>
	.secondary-table {
		text-align: center;
		background: #FAFDFF;

		th {
			background: var(--color-secondary);
			color: white;
			text-align: center;
			padding: 22px;
			border-right: 1px solid #684687;
		}
	}
</style>
